<template>
  <div class="year-statistic">
    <SectionTitle fontSize="18" hideBtn>
      <template slot="title">
        <p style="font-size: 18px">年度統計</p>
        <p class="text-gray-60 text-sub" style="margin-left: 12px">計算範圍：當年度 1/1 00:00:00 ~ 隔年度 1/31 23:59:59</p>
      </template>
    </SectionTitle>
    <StatisticBlock :data="displayDataA" />
    <StatisticBlock :data="displayDataB" />
  </div>
</template>

<script setup>
import StatisticBlock from '@/components/Report/StatisticBlock.vue'
import { GetOriginTotalAmount } from '@/api/rebate'
import { useRangeTime } from '@/use/rangeTime'
import store from '@/store'
import { computed, onMounted, ref } from 'vue'
import { get } from 'lodash'

const { getYearRange } = useRangeTime()

const data = ref(null)
const shopId = computed(() => store.getters.shop)

const totalAdd = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'ADMIN_ADD') +
    get(data.value, 'EC_ORDER_ADD') +
    get(data.value, 'LOTTERY_AWARD_ADD') +
    get(data.value, 'POINT_EXCHANGE_ADD') +
    get(data.value, 'REWARD_ACTIVITY_EC_ORDER_ADD') +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD') +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_STORE_ADD') +
    get(data.value, 'REWARD_ACTIVITY_SALE_RECORD_ADD') +
    get(data.value, 'SALE_RECORD_ADD') +
    get(data.value, 'SERIAL_ACTIVITY_ADD') +
    get(data.value, 'EVENT_PLAYBOOK_SEND') +
    get(data.value, 'SHOP_POINT_LINK_ADD')
})
const totalUse = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'EC_ORDER_USE', 0) +
    get(data.value, 'SALE_RECORD_USE', 0) +
    get(data.value, 'MEMBER_STORE_USE', 0)
})
const totalCancel = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'ADMIN_USE', 0) +
    get(data.value, 'EC_ORDER_ADD_CANCEL', 0) +
    get(data.value, 'SALE_RECORD_ADD_CANCEL', 0) +
    get(data.value, 'POINT_EXCHANGE_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL', 0)
})
const total = computed(() => {
  if (!data.value) return '-'
  const count = totalAdd.value +
    totalUse.value +
    totalCancel.value +
    get(data.value, 'EXPIRED')
  return count
})

const displayDataA = computed(() => [
  { label: '實際回饋金額', value: totalAdd.value },
  { label: '實際折抵金額', value: totalUse.value },
])

const displayDataB = computed(() => [
  { label: '過期金額', value: get(data.value, 'EXPIRED', '-') },
  { label: '回收金額', value: totalCancel.value },
  { label: '未實現金額', value: total.value, tooltip: '未實現金額 = 實際回饋金額 - 實際折抵金額 - 回收金額 - 過期金額' },
])

const getOriginTotalAmount = async () => {
  const dates = getYearRange()
  const [res, err] = await GetOriginTotalAmount({
    shopId: shopId.value,
    createStart: dates[0],
    createEnd: dates[1],
  })
  if (err) {
    window.$message.error(err)
    return
  }
  data.value = res
}

onMounted(async () => {
  await getOriginTotalAmount()
})
</script>

<style scoped lang="postcss">

</style>
